import { CommonModule } from '@angular/common'
import { Component, inject } from '@angular/core'
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms'
import { ButtonModule } from 'primeng/button'
import { InputTextModule } from 'primeng/inputtext'
import { LayoutService } from 'src/app/layout/service/app.layout.service'
import { Router } from '@angular/router'
import { signIn } from 'supertokens-web-js/recipe/emailpassword'

@Component({
  templateUrl: './login.component.html',
  standalone: true,
  imports: [CommonModule, ButtonModule, InputTextModule, ReactiveFormsModule],
})
export class LoginComponent {
  loginForm: FormGroup
  loading!: boolean
  private router = inject(Router)

  constructor(private layoutService: LayoutService) {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
    })
  }

  get dark(): boolean {
    return this.layoutService.config().colorScheme !== 'light'
  }

  async signInClicked() {
    try {
      this.loading = true
      let response = await signIn({
        formFields: [
          {
            id: 'email',
            value: this.loginForm.value.email,
          },
          {
            id: 'password',
            value: this.loginForm.value.password,
          },
        ],
      })

      if (response.status === 'FIELD_ERROR') {
        response.formFields.forEach((formField) => {
          if (formField.id === 'email') {
            // Email validation failed (for example incorrect email syntax).
            window.alert(formField.error)
          }
        })
      } else if (response.status === 'WRONG_CREDENTIALS_ERROR') {
        window.alert('Email password combination is incorrect.')
      } else if (response.status === 'SIGN_IN_NOT_ALLOWED') {
        // this can happen due to automatic account linking. Tell the user that their
        // input credentials is wrong (so that they do through the password reset flow)
      } else {
        // sign in successful. The session tokens are automatically handled by
        // the frontend SDK.
        this.router.navigate(['/dashboard'])
      }
      console.log('response', response)
      this.loading = false
    } catch (err: any) {
      this.loading = false
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        window.alert(err.message)
      } else {
        window.alert('Oops! Something went wrong.')
      }
    }
  }
}
